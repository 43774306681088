import { getMtaApBasicData } from './helpers/basicHelper';
import { newAdminPanelBaseUrl } from './helpers/config';

const goToNewAdmin=()=>{
    location.replace(`${newAdminPanelBaseUrl}/login`);
};

window.goToNewAdmin=goToNewAdmin;  

// const sidebarWalletPageId = byId('sidebarWalletPageId');

const depositCredit = getMtaApBasicData().depositCredit;

if(depositCredit >  0){

    // removing wallet page from all admin panels 

    // addDNoneClass(sidebarWalletPageId,false);
}